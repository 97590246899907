<template>
  <div class="content">
    <common-breadcrumb />
    <div class="table-wrap">
      <div class="top-tab flex-space-between">
        <div class="tab-text">我的预约</div>
      </div>
      <appointmentMain />
    </div>
  </div>
</template>

<script>
import appointmentMain from './component/appointment-main';
export default {
  name: 'myAppointment',
  components: {
    appointmentMain
  }
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 20px;
  padding: 25px 25px 20px 25px;
  width: 100%;
  /*min-height: 555px;*/
  height: auto;
  background: #ffffff;
  .bread-menu-wrap {
    line-height: 40px;
    font-size: 13px;
    .bread-menu-wrap-right {
      span {
        color: #145ccd;
        cursor: pointer;
      }
    }
  }
  .table-wrap {
    .top-tab {
      height: 42px;
      border-bottom: 1px solid #e7e7e7;
      .tab-text {
        padding: 0px 20px;
        line-height: 42px;
        border-bottom: #0168b7 solid 1.5px;
        color: #0168b7;
        font-size: 16px;
      }
    }
    .table-filter {
      margin: 20px 0;
      /deep/ .flex-column {
        .left-panel {
          .filter-item:nth-child(1) {
            .el-select:nth-child(1) {
              width: 100px !important;
            }
          }
        }
      }
    }
  }
}
</style>
